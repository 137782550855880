<template>
  <header class="section-team-member-header" :style="`background-color: var(--white)`">
    <div class="page-padding">
      <div class="container-large">
        <div class="padding-vertical padding-xhuge tw-pb-0">
          <div class="team-main-wrapper">
            <div class="inner-content">
              <img alt="" loading="lazy" :src="vars.photoImage" class="cover-image" />
            </div>
            <div class="inner-content">
              <div class="margin-bottom mb-4">
                <h2
                  class="heading-medium"
                  style="
                    transform: translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg)
                      skew(0deg, 0deg);
                    transform-style: preserve-3d;
                  "
                >
                  {{ vars.fullNameText }}
                </h2>
              </div>
              <div class="margin-bottom tw-mb-4 tw-flex tw-flex-col tw-space-y-2" style="color: var(--dark-brown)">
                <span class="text-size-tiny text-weight-light office-detail__label"> PARTNER AGENT </span>
                <p class="mb-4">{{ vars.locationText }}</p>

                <nuxt-link v-if="vars.emailText" :to="`mailto:${vars.emailText}`" :external="true" class="n-link">{{
                  vars.emailText
                }}</nuxt-link>

                <nuxt-link v-if="vars.telText" :to="`tel:${vars.telText}`" :external="true" class="n-link">{{
                  vars.telText
                }}</nuxt-link>
              </div>
              <div class="max-width-medium">
                <div class="text-rich-text w-richtext">
                  <p v-for="(description, index) in descriptions" :key="index">
                    {{ description.paragraph }}
                  </p>
                </div>
              </div>
              <div class="margin-top margin-medium">
                <div
                  id="w-node-_89178180-d2d9-543d-02eb-8440c454eaf1-6b52b1a4"
                  style="opacity: 1"
                  class="button-flex-wrapper"
                >
                  <nuxt-link
                    v-if="vars.bookAValuationUrl"
                    data-w-id="e518d480-92b8-a872-761f-a0bce132015e"
                    :to="vars.bookAValuationUrl"
                    :external="true"
                    class="button w-inline-block"
                    ><div class="button-label">book a valuation</div>
                    <div style="height: 0%" class="overlay"></div></nuxt-link
                  ><nuxt-link
                    v-if="vars.whatsappUrl"
                    data-w-id="d232bdbc-f234-efa1-2d9b-04223beef0c9"
                    :external="true"
                    :to="`https://wa.me/${vars.whatsappUrl}`"
                    class="button outline w-inline-block"
                    ><div>whatsapp me</div>
                    <div
                      style="
                        transform: translate3d(-102%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg)
                          rotateZ(0deg) skew(0deg, 0deg);
                        transform-style: preserve-3d;
                      "
                      class="hover-underline"
                    ></div
                  ></nuxt-link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="divider-line"></div>
      </div>
    </div>
  </header>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';
export default defineNuxtComponent({
  name: 'SingleAgent',

  mixins: [ComponentMixin],

  data() {
    return {
      descriptions: [] as { [key: string]: string | number | null }[],
    };
  },

  created() {
    this.descriptions = this.groupedVariables.descriptions;
  },
});
</script>

<style scoped>
.office-detail__label {
  font-weight: 300;
  color: var(--brown--secondary);
  font-size: 0.8rem;
  line-height: 14.35px;
  letter-spacing: 5px;
  text-transform: uppercase;
}
.office-detail__value {
  font-weight: 300;
  color: var(--dark-brown);
  font-size: 1.3rem;
  padding-right: 4rem;
}
.w-min-content {
  width: min-content;
}

.office-detail-title {
  width: w-min-content;
  font-size: 6.25rem;
}

.social-media {
  background: var(--dark-brown);
  width: 36px;
  height: 36px;
  flex-shrink: 1;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white);
}

@media screen and (max-width: 768px) {
  .office-detail-title {
    width: max-content;
    font-size: 2rem;
  }
}

@media screen and (max-width: 991px) {
  .w-layout-grid.service-component-wrapper {
    grid-template-columns: 1fr 0rem 0fr !important;
  }
  .image-wrapper.project {
    width: unset;
  }
}

.link {
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: Inter, sans-serif;
  color: var(--brown--secondary);
  display: block;
}
</style>
