<template>
  <section class="section-home-blog" :style="`background-color: var(--${backgroundColor}) !important`">
    <div :class="variants.v3 === variant ? '' : 'page-padding'">
      <div class="container-large">
        <div
          :class="
            variants.v3 === variant
              ? 'tw-py-16'
              : variants.v1 === variant
                ? 'tw-py-16'
                : 'padding-vertical padding-xhuge'
          "
        >
          <div :class="variants.v3 === variant ? 'tw-mb-10 tw-flex tw-flex-col tw-gap-y-6' : 'top-blog-content'">
            <div class="max-width-large">
              <h2
                :class="variants.v3 === variant ? 'tw-text-4xl' : 'heading-medium'"
                style="color: var(--dark-brown)"
                v-html="titleHtml"
              ></h2>
            </div>
            <div v-if="variants.v3 === variant" class="tw-grid tw-grid-cols-6">
              <div class="tw-col-span-3">
                <nc-button link="/blogs" :external="true" :is-link="true" label="LATEST BLOGS"> </nc-button>
              </div>
            </div>
            <div v-else>
              <nc-button link="/blogs" :external="true" :is-link="true" label="LATEST BLOGS"> </nc-button>
            </div>
          </div>
          <div class="articles-list-wrapper w-dyn-list">
            <div
              role="list"
              :class="variant === variants.v3 ? 'tw-flex tw-flex-col tw-gap-y-8' : 'blog-posts-component w-dyn-items'"
            >
              <div v-for="(blog, index) in blogs" :key="`blog-${index}`" role="listitem" class="w-dyn-item">
                <nuxt-link
                  :to="blog.url_in_use"
                  data-w-id="f5198688-baf0-dae4-22dd-e42c6e60a76a"
                  class="link-content-block blog w-inline-block"
                  :external="true"
                >
                  <div class="margin-bottom margin-medium">
                    <div class="image-wrapper blog">
                      <img
                        alt=""
                        loading="lazy"
                        style="
                          -webkit-transform: translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0)
                            skew(0, 0);
                          -moz-transform: translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0)
                            skew(0, 0);
                          -ms-transform: translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0)
                            skew(0, 0);
                          transform: translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
                        "
                        :src="blog.image"
                        class="cover-image"
                      />
                    </div>
                  </div>
                  <div class="blog-content tw-w-full">
                    <div class="margin-bottom margin-small tw-mb-2 tw-h-14">
                      <h3 style="opacity: 1" class="heading-xsmall">
                        {{ blog.title }}
                      </h3>
                    </div>
                    <p class="text-size-small">
                      {{ blog.description }}
                    </p>
                  </div>
                </nuxt-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import Loader from '~/components/common/Loader.vue';
import NotFound from '~/components/theme2/base/NotFound.vue';
import NcButton from '~/components/common/inputs/NcButton.vue';
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'Theme2ManualBlogsBox',
  components: { NcButton, NotFound, Loader },

  mixins: [ComponentMixin],

  data() {
    return {
      variants: {
        v1: 'v1',
        v2: 'v2',
        v3: 'v3',
      },
    };
  },

  computed: {
    backgroundColor() {
      return getVariable(this.component, 'background-color');
    },

    variant() {
      return useVariant(this.component);
    },

    blogs() {
      const blogs = this.groupedVariables.blogs;

      return blogs.map((blog) => {
        return {
          ...blog,
          url_in_use: blog.url && blog.url.includes('blogs') ? blog.url : `/blogs/${blog.url}`,
        };
      });
    },

    titleHtml() {
      return this.vars.titleHtml || 'Take a look at our latest blogs';
    },
  },

  watch: {},
});
</script>

<style scoped>
.link-content-block.blog {
  flex-direction: column !important;
}
.image-wrapper.blog {
  width: 100% !important;
}

.selected-page {
  border-bottom: 2px solid var(--brown);
}

.blog-content h3 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>
