<template>
  <footer class="footer" style="background-color: var(--dark-brown)">
    <div class="page-padding">
      <div class="container-large">
        <div class="padding-vertical padding-xhuge">
          <div class="w-layout-grid footer-component-grid">
            <div id="w-node-_103b4bea-4a5e-266e-2bf1-8a17dc59e522-dc59e506" class="inner-content block-1">
              <div class="footer-title">{{ vars.title1Text }}</div>
              <div class="margin-top margin-medium">
                <div class="nav-footer-content">
                  <ul role="list" class="footer-nav-list w-list-unstyled">
                    <li v-for="(menu, index) in footerMenuItems" :key="`page-${index}`" class="footer-nav-item">
                      <nuxt-link
                        v-if="menu.url !== 'url'"
                        :to="menu.url"
                        class="footer-nav-link w-inline-block w--current"
                        data-w-id="103b4bea-4a5e-266e-2bf1-8a17dc59e529"
                        :external="true"
                        :target="menu.is_open_new_tab ? '_blank' : ''"
                      >
                        {{ menu.name }}
                        <div class="hover-underline"></div>
                      </nuxt-link>
                      <a
                        v-else
                        :href="menu.url"
                        aria-current="page"
                        class="footer-nav-link w-inline-block w--current"
                        data-w-id="103b4bea-4a5e-266e-2bf1-8a17dc59e529"
                      >
                        {{ menu.name }}
                        <div class="hover-underline"></div>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div id="w-node-_103b4bea-4a5e-266e-2bf1-8a17dc59e54b-dc59e506" class="inner-content block-2">
              <div class="footer-title">{{ vars.title2Text }}</div>
              <div class="margin-top margin-medium">
                <div class="nav-footer-content">
                  <ul role="list" class="footer-nav-list w-list-unstyled">
                    <li v-for="(contact, index) in contacts" :key="`contact-${index}`" class="footer-nav-item">
                      <nuxt-link
                        data-w-id="103b4bea-4a5e-266e-2bf1-8a17dc59e552"
                        :to="contact.link"
                        class="footer-nav-link w-inline-block"
                        :external="true"
                        :target="contact.is_new_tab ? '_blank' : ''"
                      >
                        <div>{{ contact.item }}</div>
                        <div class="hover-underline"></div>
                      </nuxt-link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div
              id="w-node-_103b4bea-4a5e-266e-2bf1-8a17dc59e50b-dc59e506"
              class="bydesign-center-footer d-flex flex-column align-items-center pt-3"
            >
              <nuxt-link
                to="/"
                :external="true"
                aria-current="page"
                class="logo-link w-inline-block w--current tw-mb-12 tw-mt-8 lg:tw-mt-0"
                ><img :src="company.logo" loading="lazy" :width="200" alt="ByDesign"
              /></nuxt-link>
              <div class="footer-description d-flex justify-content-center flex-column align-items-center">
                <p>{{ vars.title3Text }}</p>
                <div class="tw-flex tw-flex-wrap tw-items-center tw-justify-center tw-gap-x-4 tw-gap-y-2">
                  <NuxtImg
                    v-for="(reference, index) in references"
                    :key="`reference-${index}`"
                    class="reference-image"
                    :src="reference.image"
                    :width="100"
                    quality="60"
                  />
                </div>
              </div>
              <div
                class="footer-description margin-top margin-medium d-flex flex-wrap align-items-center gap-2 flex-column mt-4"
              >
                <p>{{ vars.title4Text }}</p>

                <div class="social-media-content">
                  <a
                    v-if="vars.facebookUrl"
                    :href="vars.facebookUrl"
                    target="_blank"
                    class="social-link w-inline-block"
                  >
                    <div></div>
                  </a>
                  <a
                    v-if="vars.instagramUrl"
                    :href="vars.instagramUrl"
                    target="_blank"
                    class="social-link w-inline-block"
                  >
                    <div></div>
                  </a>

                  <a
                    v-if="vars.linkedinUrl"
                    :href="vars.linkedinUrl"
                    target="_blank"
                    class="social-link w-inline-block"
                  >
                    <div></div>
                  </a>
                  <a v-if="vars.youtubeUrl" :href="vars.youtubeUrl" target="_blank" class="social-link w-inline-block">
                    <div></div>
                  </a>
                </div>
              </div>
            </div>

            <div id="w-node-_103b4bea-4a5e-266e-2bf1-8a17dc59e56f-dc59e506" class="inner-content block-3">
              <div class="margin-top margin-medium">
                <div class="nav-footer-content">
                  <ul role="list" class="footer-nav-list w-list-unstyled">
                    <br />
                    <li v-if="vars.branchNameText && variant === 'office_detail'" class="footer-nav-item">
                      <a
                        data-w-id="103b4bea-4a5e-266e-2bf1-8a17dc59e529"
                        aria-current="page"
                        class="footer-nav-link w-inline-block w--current branch-name"
                      >
                        <div>{{ vars.branchNameText }}</div>
                        <div class="hover-underline"></div>
                      </a>
                    </li>

                    <li
                      v-if="
                        variant === 'office_detail' &&
                        (vars.branchFacebookUrl ||
                          vars.branchInstagramUrl ||
                          vars.branchLinkedinUrl ||
                          vars.branchYoutubeUrl)
                      "
                      class="footer-nav-item tw-mb-5"
                    >
                      <div class="social-media-content">
                        <a
                          v-if="vars.branchFacebookUrl"
                          :href="vars.branchFacebookUrl"
                          target="_blank"
                          class="social-link w-inline-block"
                        >
                          <div></div>
                        </a>
                        <a
                          v-if="vars.branchInstagramUrl"
                          :href="vars.branchInstagramUrl"
                          target="_blank"
                          class="social-link w-inline-block"
                        >
                          <div></div>
                        </a>

                        <a
                          v-if="vars.branchLinkedinUrl"
                          :href="vars.branchLinkedinUrl"
                          target="_blank"
                          class="social-link w-inline-block"
                        >
                          <div></div>
                        </a>
                        <a
                          v-if="vars.branchYoutubeUrl"
                          :href="vars.branchYoutubeUrl"
                          target="_blank"
                          class="social-link w-inline-block"
                        >
                          <div></div>
                        </a>
                      </div>
                    </li>

                    <li v-if="vars.companyTradingAsText" class="footer-nav-item">
                      <a
                        data-w-id="103b4bea-4a5e-266e-2bf1-8a17dc59e529"
                        aria-current="page"
                        class="footer-nav-link w-inline-block w--current"
                      >
                        <div>{{ vars.companyTradingAsText }}</div>
                        <div class="hover-underline"></div>
                      </a>
                    </li>
                    <li v-if="vars.companyNameText" class="footer-nav-item">
                      <a data-w-id="103b4bea-4a5e-266e-2bf1-8a17dc59e52e" class="footer-nav-link w-inline-block">
                        <div>{{ vars.companyNameText }}</div>
                        <div class="hover-underline"></div>
                      </a>
                    </li>
                    <li v-if="vars.companyNumberNumber" class="footer-nav-item">
                      <a data-w-id="103b4bea-4a5e-266e-2bf1-8a17dc59e533" class="footer-nav-link w-inline-block">
                        <div>Company Number: {{ vars.companyNumberNumber }}</div>
                        <div class="hover-underline"></div>
                      </a>
                    </li>
                    <li class="footer-nav-item">
                      <a data-w-id="103b4bea-4a5e-266e-2bf1-8a17dc59e538" class="footer-nav-link w-inline-block">
                        <div>Registered Address: {{ vars.companyAddressText }}</div>
                        <div class="hover-underline"></div>
                      </a>
                    </li>
                    <li v-if="vars.companyRedressSchemeItemText" class="footer-nav-item">
                      <a data-w-id="103b4bea-4a5e-266e-2bf1-8a17dc59e53d" class="footer-nav-link w-inline-block">
                        <div>
                          Redress Scheme:
                          {{ vars.companyRedressSchemeItemText }}
                        </div>
                        <div class="hover-underline"></div>
                      </a>
                    </li>
                    <li v-if="vars.companyRedressSchemeLinkUrl" class="footer-nav-item">
                      <a
                        data-w-id="103b4bea-4a5e-266e-2bf1-8a17dc59e547"
                        :href="vars.companyRedressSchemeLinkUrl"
                        class="footer-nav-link w-inline-block"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <div>{{ vars.companyRedressSchemeLinkUrl }}</div>
                        <div class="hover-underline"></div>
                      </a>
                    </li>
                    <li
                      v-if="redressLogos && redressLogos.length"
                      class="footer-nav-item tw-flex tw-flex-wrap tw-justify-center tw-gap-x-2 lg:tw-justify-start"
                    >
                      <a
                        v-for="(logo, index) in redressLogos"
                        :key="`redressLogos-${index}`"
                        data-w-id="103b4bea-4a5e-266e-2bf1-8a17dc59e547"
                        class="footer-nav-link w-inline-block"
                      >
                        <img
                          style="max-height: 42px"
                          :title="logo.schemelogoalt"
                          :alt="logo.schemelogoalt"
                          :src="logo.schemelogo"
                        />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-bottom-content">
      <div class="page-padding d-flex flex-column align-items-center gap-5">
        <div class="text-bottom">
          Powered by
          <b>Neuron</b>
          |
          <b>Iceberg Digital</b>
        </div>
        <div class="text-bottom">© 2024 {{ vars.companyText }}. All rights reserved.</div>
      </div>
    </div>
  </footer>
</template>

<script lang="ts">
import { mapState } from 'pinia';
import { ComponentMixin } from '~/mixins/component.mixin';
import { useWebsiteStore } from '~/units/website/store';

export default defineNuxtComponent({
  name: 'Theme2BaseFooterOne',

  mixins: [ComponentMixin],

  computed: {
    ...mapState(useWebsiteStore, ['footerMenuItems']),

    variant() {
      return this.component.component_variant.name;
    },

    contacts() {
      return this.groupedVariables.contacts;
    },

    redressLogos() {
      return this.groupedVariables.redress;
    },

    references() {
      return this.groupedVariables.references;
    },

    routeUrl() {
      const route = useRoute();
      return route.path;
    },
  },
});
</script>

<style scoped>
.footer-component-grid {
  grid-template-columns: 1fr 1fr 4fr 2.5fr;
  grid-column-gap: 3%;
}

.padding-xhuge {
  padding: 4rem 0 0;
}

.inner-content {
  padding: 7rem 0 0;
}

.footer-description {
  max-width: unset;
}

.footer-nav-list {
  margin-right: unset;
}

.footer-title,
.footer-nav-link,
.footer-description p {
  font-weight: 200;
  color: var(--white);
}

@media screen and (max-width: 767px) {
  .footer-component-grid {
    grid-template-columns: 1fr !important;
    grid-row-gap: 1rem;
  }
  .block-1 {
    order: 1;
  }
  .block-2 {
    order: 2;
  }
  .block-3 {
    order: 3;
  }
  .bydesign-center-footer {
    order: 0;
  }
  .w-layout-grid.footer-component-grid {
    display: flex;
    flex-direction: column;
  }
  .inner-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0;
  }
  .reference-image {
    width: 34%;
  }
  .footer-description {
    margin-top: 3rem;
  }
  .inner-content {
    margin-top: 2rem;
  }
  .footer-bottom-content {
    margin-top: 3rem;
  }
  .padding-xhuge {
    padding: 0;
  }
}
.social-link {
  background-color: var(--white);
  border: none;
}
.social-link div {
  color: var(--dark-brown);
}
.footer-bottom-content {
  background-color: transparent;
  padding-top: unset;
  padding-bottom: 3rem;
}
.footer-title {
  font-size: 1rem;
}

.reference-image {
  object-fit: contain;
}

.footer-description {
  margin-top: unset;
}

.branch-name {
  font-weight: 300;
  font-size: 0.8rem;
  line-height: 14.35px;
  letter-spacing: 5px;
}
</style>
