<template>
  <section class="section-services-content pt-5" :style="`background-color: var(--white)`">
    <div class="page-padding">
      <div class="container-large">
        <div class="padding-bottom padding-huge">
          <div class="why-box d-flex justify-content-between align-items-center" style="gap: 12.5rem">
            <div class="w-100">
              <h2 class="heading-medium mb-2" style="font-size: 5rem">
                {{ vars.titleText }}
              </h2>
              <div class="margin-top margin-small">
                <div class="max-width-full">
                  <p
                    v-for="(description, index) in descriptions"
                    :key="index"
                    class="text-size-tiny text-weight-light mb-2"
                  >
                    {{ description.paragraph }}
                  </p>
                </div>
              </div>
              <div v-if="vars.buttonUrl" class="margin-top margin-medium mt-5">
                <div>
                  <nuxt-link
                    data-w-id="bd454024-605c-912a-bc49-318b72174c6f"
                    :external="true"
                    style="width: auto !important; display: inline-block !important"
                    :to="vars.buttonUrl"
                    class="button w-inline-block"
                  >
                    <div class="button-label text-weight-light">
                      {{ vars.buttonText }}
                    </div>
                    <div style="height: 0%" class="overlay"></div>
                  </nuxt-link>
                </div>
              </div>
            </div>
            <div class="w-100">
              <div>
                <div class="text-size-regular text-2-columns mt-0">
                  <p style="font-size: 0.75rem">01</p>
                  <h2 class="d-block mb-3 heading-xsmall mt-1">
                    {{ vars.cultures1TitleText }}
                  </h2>

                  <span class="fs-1">{{ vars.cultures1DescriptionText }}</span>

                  <br /><br />
                  <p style="font-size: 0.75rem">02</p>
                  <h2 class="d-block mb-3 heading-xsmall mt-1">
                    {{ vars.cultures2TitleText }}
                  </h2>

                  <span class="fs-1">{{ vars.cultures2DescriptionText }}</span>
                </div>
              </div>
              <div class="text-size-regular mt-0">
                <p style="font-size: 0.75rem">03</p>
                <h2 class="d-block mb-3 heading-xsmall mt-1">
                  {{ vars.cultures3TitleText }}
                </h2>
                <span class="fs-1">{{ vars.cultures3DescriptionText }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'OurCulture',

  mixins: [ComponentMixin],

  data() {
    return {
      descriptions: [] as { [key: string]: string | number | null }[],
    };
  },

  created() {
    this.descriptions = this.groupedVariables.descriptions;
  },
});
</script>

<style scoped>
.fs-1 {
  font-size: 1rem !important;
}
@media screen and (max-width: 991px) {
  .why-box {
    flex-wrap: wrap;
    gap: 1rem !important;
  }
  .image-wrapper {
    grid-area: 1 / 1 / 2 / 4 !important;
  }
  .text-size-regular {
    width: unset;
  }
}
</style>
