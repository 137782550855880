<template>
  <section class="section-services-content pt-5" :style="`background-color: var(--white)`">
    <div class="page-padding">
      <div class="container-large">
        <div class="padding-bottom padding-huge">
          <div class="d-flex flex-column align-items-center justify-content-center w-100 pb-5">
            <h2 class="heading-medium">{{ vars.titleText }}</h2>
            <div class="margin-top margin-small">
              <div class="max-width-large">
                <p class="text-size-tiny text-weight-light text-center">
                  {{ vars.descriptionText }}
                </p>
              </div>
            </div>
          </div>
          <div class="margin-top margin-xlarge">
            <div class="w-dyn-list">
              <Swiper
                :modules="[SwiperNavigation]"
                :slides-per-view="3"
                :space-between="30"
                effect="fade"
                :navigation="{
                  nextEl: '.swiper-button-next',
                  prevEl: '.swiper-button-prev',
                }"
                :breakpoints="{
                  320: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                  },
                  1080: {
                    slidesPerView: 2,
                    spaceBetween: 42,
                  },
                  1336: {
                    slidesPerView: 3,
                    spaceBetween: 42,
                  },
                }"
                class="slider"
                @swiper="onSwiper"
              >
                <SwiperSlide
                  v-for="(team, index) in teams"
                  :key="`team-${index}`"
                  role="listitem"
                  class="team-member-item w-dyn-item"
                >
                  <a
                    id="w-node-ee1a785f-164b-ccdd-3304-7868a8ac7fb4-89606d85"
                    data-w-id="ee1a785f-164b-ccdd-3304-7868a8ac7fb4"
                    href="#"
                    class="link-content-block team-member w-inline-block"
                  >
                    <div class="image-wrapper project">
                      <img
                        alt=""
                        loading="lazy"
                        style="
                          -webkit-transform: translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0)
                            skew(0, 0);
                          -moz-transform: translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0)
                            skew(0, 0);
                          -ms-transform: translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0)
                            skew(0, 0);
                          transform: translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
                        "
                        :src="team.photo"
                        class="cover-image project"
                      />
                      <div
                        style="
                          display: block;
                          -webkit-transform: translate3d(100%, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0)
                            skew(0, 0);
                          -moz-transform: translate3d(100%, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0)
                            skew(0, 0);
                          -ms-transform: translate3d(100%, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0)
                            skew(0, 0);
                          transform: translate3d(100%, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0)
                            skew(0, 0);
                        "
                        class="overlay-animation"
                      ></div>
                    </div>
                    <div class="margin-top margin-medium" style="margin-bottom: 20px !important">
                      <h3 style="opacity: 1" class="heading-xsmall">
                        {{ team.fullname }}
                      </h3>
                    </div>
                    <p class="text-size-small mb-0">{{ team.email }}</p>
                    <p class="text-size-small">
                      {{ team.tel }}
                    </p>
                  </a>
                </SwiperSlide>
                <div class="controls d-xl-none">
                  <div
                    data-w-id="080a9ca2-7a2d-2ca3-3b22-f42b27569daf"
                    class="slide-left-arrow w-slider-arrow-left"
                    @click="() => swiper.slidePrev()"
                  >
                    <img
                      src="/cdn/theme2/images/left-arrow.svg"
                      loading="lazy"
                      alt="Left Arrow - Inner Webflow Template"
                      class="arrow-icon slider-arrow-transform"
                    />
                    <div class="slide-circle slider-circle-transform"></div>
                  </div>
                  <div
                    data-w-id="080a9ca2-7a2d-2ca3-3b22-f42b27569db1"
                    class="slide-right-arrow w-slider-arrow-right"
                    @click="() => swiper.slideNext()"
                  >
                    <img
                      src="/cdn/theme2/images/right-arrow.svg"
                      loading="lazy"
                      alt="Right Arrow - Inner Webflow Template"
                      class="arrow-icon slider-arrow-transform"
                    />
                    <div class="slide-circle right"></div>
                  </div>
                  <div class="slide-nav w-slider-nav w-round"></div>
                </div>
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';
export default defineNuxtComponent({
  name: 'Team',

  mixins: [ComponentMixin],

  data() {
    return {
      teams: [] as { [key: string]: string | number | null }[],
      swiper: {},
    };
  },

  created() {
    this.teams = this.groupedVariables.teams;
  },

  methods: {
    onSwiper(swiper: object) {
      this.swiper = swiper;
    },
  },
});
</script>

<style scoped>
.cover-image {
  height: auto;
  min-height: 400px;
  max-height: 400px;
}

.image-wrapper {
  width: 100%;
}
</style>
