<template>
  <section class="section-services-content pt-5" :style="`background-color: var(--white)`">
    <div class="page-padding pt-5">
      <div class="container-large">
        <div class="padding-bottom padding-huge">
          <div class="we-are-hiring w-layout-grid service-component-wrapper">
            <div style="align-self: center">
              <h2 class="heading-medium">{{ vars.titleText }}</h2>
              <p style="color: var(--dark-brown)">{{ vars.emailText }}</p>
              <p style="color: var(--dark-brown)">{{ vars.phoneNumberText }}</p>
              <div class="margin-top margin-small">
                <div class="max-width-full">
                  <p v-for="(description, i) in descriptions" :key="i" class="text-size-tiny text-weight-light">
                    {{ description.paragraph }}
                  </p>
                </div>
              </div>
              <div class="margin-top margin-medium">
                <div>
                  <nuxt-link
                    data-w-id="6ebe58be-9850-ee85-6f64-5ba86359989e"
                    :external="true"
                    style="width: auto !important; display: inline-block !important"
                    :to="vars.buttonUrl"
                    class="button"
                  >
                    <div class="button-label text-weight-light">
                      {{ vars.buttonText }}
                    </div>
                    <div
                      style="
                        -webkit-transform: translate3d(-102%, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0)
                          skew(0, 0);
                        -moz-transform: translate3d(-102%, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0)
                          skew(0, 0);
                        -ms-transform: translate3d(-102%, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0)
                          skew(0, 0);
                        transform: translate3d(-102%, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
                      "
                      class="hover-underline"
                    ></div>
                  </nuxt-link>
                </div>
              </div>
            </div>
            <div id="w-node-_67e6759a-e0fe-7f50-6614-460154e4a2c7-89606db0" class="service-details-content">
              <div class="margin-top margin-small">
                <div class="max-width-full">
                  <img :src="vars.coverImage" loading="lazy" alt="" class="cover-image" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';
export default defineNuxtComponent({
  name: 'WeAreHiring',

  mixins: [ComponentMixin],

  data() {
    return {
      descriptions: [] as { [key: string]: string | number | null }[],
    };
  },

  created() {
    this.descriptions = this.groupedVariables.descriptions;
  },
});
</script>

<style scoped>
.office-detail__label {
  font-weight: 300;
  color: var(--brown--secondary);
  font-size: 0.8rem;
  line-height: 14.35px;
  letter-spacing: 5px;
  text-transform: uppercase;
}
.office-detail__value {
  font-weight: 300;
  color: var(--dark-brown);
  font-size: 1.3rem;
  padding-right: 4rem;
}
.w-min-content {
  width: min-content;
}

.office-detail-title {
  width: w-min-content;
  font-size: 6.25rem;
}

.social-media {
  background: var(--dark-brown);
  width: 36px;
  height: 36px;
  flex-shrink: 1;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white);
}
.we-are-hiring {
  grid-template-columns: 1fr 5rem 1fr;
}

@media screen and (max-width: 991px) {
  .we-are-hiring {
    grid-template-columns: auto 1rem 1fr;
  }
}

@media screen and (max-width: 768px) {
  .office-detail-title {
    width: max-content;
    font-size: 2rem;
  }
}
</style>
