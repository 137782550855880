<template>
  <component
    :is="component.component.name"
    v-for="(component, index) in specificComponents"
    :key="`${keyPrefix}-${index}`"
    :component="component"
  ></component>
</template>

<script lang="ts">
import BlogsBox from './components/BlogsBox.vue';
import PropertiesBox from '~/components/theme2/components/PropertiesBox.vue';
import BlogsTopBar from '~/components/theme2/components/BlogsTopBar.vue';
import SelectedBlogsBox from '~/components/theme2/components/SelectedBlogsBox.vue';
import BlogDetail from '~/components/theme2/components/BlogDetail.vue';
import HomeIntro from '~/components/theme2/components/HomeIntro.vue';
import PropertySearchBox from '~/components/theme2/components/PropertySearchBox.vue';
import References from '~/components/theme2/components/References.vue';
import ContentBox from '~/components/theme2/components/ContentBox.vue';
import HeadsUpAlertCTA from '~/components/theme2/components/HeadsUpAlertCTA.vue';
import ValuationCTA from '~/components/theme2/components/ValuationCTA.vue';
import GuidesBox from '~/components/theme2/components/GuidesBox.vue';
import ScrollAnimationTexts from '~/components/theme2/components/ScrollAnimationTexts.vue';
import PropertyDetail from '~/components/theme2/components/PropertyDetail.vue';
import Properties from '~/components/theme2/components/Properties.vue';
import Register from '~/components/common/register/Register.vue';
import RegisterEntry from '~/components/common/register/RegisterEntry.vue';
import OfficeDetail from '~/components/theme2/components/OfficeDetail.vue';
import PropertiesEntry from '~/components/common/properties/PropertiesEntry.vue';
import WeAreHiring from '~/components/theme2/components/WeAreHiring.vue';
import AboutAccordion from '~/components/theme2/components/AboutAccordion.vue';
import OfficeDetailIntro from '~/components/theme2/components/OfficeDetailIntro.vue';
import Testimonials from '~/components/theme2/components/Testimonials.vue';
import Team from '~/components/theme2/components/Team.vue';
import SingleAgent from '~/components/theme2/components/SingleAgent.vue';
import PageIntro from '~/components/theme2/components/PageIntro.vue';
import Achievements from '~/components/theme2/components/Achievements.vue';
import GuideDetail from '~/components/theme2/components/GuideDetail.vue';
import JoinUsIntro from '~/components/theme2/components/JoinUsIntro.vue';
import FAQ from '~/components/theme2/components/FAQ.vue';
import OurCulture from '~/components/theme2/components/OurCulture.vue';
import LocationsList from '~/components/theme2/components/LocationsList.vue';
import OurOptions from '~/components/theme2/components/OurOptions.vue';
import SectionIntro from '~/components/theme2/components/SectionIntro.vue';
import VideoPopup from '~/components/theme2/components/VideoPopup.vue';
import FooterOne from '~/components/theme2/base/footer/FooterOne.vue';
import FormContainer from '~/components/library/FormContainer.vue';
import NotFoundPage from '~/components/theme2/components/NotFoundPage.vue';
import Separator from '~/components/library/Separator.vue';
import ManualBlogsBox from '~/components/theme2/components/ManualBlogsBox.vue';
import RedirectTo from '~/components/common/RedirectTo.vue';

import PropertiesGridBox from '~/components/theme2/components/PropertiesGridBox.vue';
import ViewAgents from '~/components/theme2/components/ViewAgents.vue';
import UpdateProfileEntry from '~/components/library/heads_up_alerts/UpdateProfileEntry.vue';

export default defineNuxtComponent({
  props: {
    specificComponents: {
      required: false,
      default: () => [],
    },

    keyPrefix: {
      required: false,
      default: 'dynamicComponent',
      type: String,
    },
  },

  components: {
    BlogsBox,
    PropertiesBox,
    BlogsTopBar,
    SelectedBlogsBox,
    BlogDetail,
    HomeIntro,
    PropertySearchBox,
    References,
    ContentBox,
    HeadsUpAlertCTA,
    ValuationCTA,
    GuidesBox,
    ScrollAnimationTexts,
    PropertyDetail,
    Properties,
    Register,
    RegisterEntry,
    OfficeDetail,
    PropertiesEntry,
    WeAreHiring,
    AboutAccordion,
    OfficeDetailIntro,
    Testimonials,
    Team,
    SingleAgent,
    PropertiesGridBox,
    PageIntro,
    Achievements,
    GuideDetail,
    JoinUsIntro,
    FAQ,
    OurCulture,
    LocationsList,
    OurOptions,
    SectionIntro,
    VideoPopup,
    FooterOne,
    FormContainer,
    NotFoundPage,
    Separator,
    ManualBlogsBox,
    RedirectTo,
    ViewAgents,
    UpdateProfileEntry,
  },
});
</script>
