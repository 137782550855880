<template>
  <section class="section-home-blog" style="overflow-x: hidden" :style="`background-color: var(--white) !important`">
    <div class="page-padding">
      <div class="container-large">
        <div class="padding-vertical padding-xhuge">
          <div class="top-blog-content">
            <div class="d-flex flex-row justify-content-center w-100">
              <h2 class="heading-medium text-animation text-center">
                <span ref="animatedSpan" class="animated-title"
                  >{{ vars.part1Text }} &nbsp;{{ vars.part2Text }} <br />{{ vars.part3Text }}
                </span>
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { useWindowSize } from '@vueuse/core';
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'ScrollAnimationTextsTheme2',

  mixins: [ComponentMixin],

  computed: {},

  watch: {},
  mounted() {
    window.addEventListener('scroll', this.revealSpans);
  },

  beforeUnmount() {
    window.removeEventListener('scroll', this.revealSpans);
  },

  methods: {
    revealSpans() {
      const { height } = useWindowSize();
      const animatedSpan = this.$refs.animatedSpan;
      const triggerDistance = height.value * 0.85;
      let distanceFromTop = animatedSpan.offsetTop;
      let parent = animatedSpan.offsetParent;

      while (parent !== null) {
        distanceFromTop += parent.offsetTop;
        parent = parent.offsetParent;
      }

      distanceFromTop -= window.pageYOffset;
      let math = 1 - (Math.max(distanceFromTop, 0) - triggerDistance) / 5;
      math = math < 0 ? 0 : math > 100 ? 100 : math;

      animatedSpan.style.backgroundSize = `${math}% 100%`;
    },
  },
});
</script>

<style scoped>
@keyframes reveal {
  from {
    opacity: 0;
    clip-path: inset(45% 20% 45% 20%);
  }
  to {
    opacity: 1;
    clip-path: inset(0% 0% 0% 0%);
  }
}

.text-animation {
  animation: linear reveal both;
  animation-timeline: view();
  animation-range: entry 25% cover 50%;
}

span {
  color: var(--dark-brown-rgba) !important;
  background-clip: text;
  background-repeat: no-repeat;
  background-image: linear-gradient(90deg, var(--dark-brown), var(--dark-brown));
}
.heading-medium {
  line-height: 1.3;
}
</style>
