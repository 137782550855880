<template>
  <div class="section-home-projects py-5" style="width: 100%; background-color: var(--dark-brown)">
    <div id="wrapper py-5">
      <div class="row g-0 d-flex flex-row justify-content-center py-5">
        <div class="col-12 d-flex flex-row justify-content-center">
          <h2 style="color: var(--white)" class="heading-medium mb-5">
            {{ variables.boxTitle }}
          </h2>
        </div>
        <div class="col-md-10 col-11 row g-0 d-flex flex-row justify-content-center align-items-center">
          <div
            v-for="(image, key) in images"
            :key="`reference-image-${key}`"
            class="col-xl-4 col-6 d-flex flex-row justify-content-center align-items-center"
          >
            <img class="reference-image" alt="image" :src="image" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'ReferencesTheme2',

  mixins: [ComponentMixin],

  data() {
    return {
      keyword: '',
      searchType: 1,
    };
  },

  computed: {
    images() {
      return [
        this.variables.referenceOneImage,
        this.variables.referenceTwoImage,
        this.variables.referenceThreeImage,
        this.variables.referenceFourImage,
        this.variables.referenceFiveImage,
        this.variables.referenceSixImage,
      ];
    },

    variables() {
      return {
        boxTitle: getVariable(this.component, 'box_title-text'),
        referenceOneImage: getVariable(this.component, 'reference_one-image'),
        referenceTwoImage: getVariable(this.component, 'reference_two-image'),
        referenceThreeImage: getVariable(this.component, 'reference_three-image'),
        referenceFourImage: getVariable(this.component, 'reference_four-image'),
        referenceFiveImage: getVariable(this.component, 'reference_five-image'),
        referenceSixImage: getVariable(this.component, 'reference_six-image'),
      };
    },
  },

  methods: {
    onSearchTypeClickd(type = 1) {
      this.searchType = type;
    },
  },

  mounted() {},
});
</script>

<style scoped>
.reference-image {
  max-width: 150px;
}

@media (min-width: 768px) {
  .reference-image {
    max-width: 300px;
  }
}
</style>
