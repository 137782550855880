import { capitalizeFirstLetters } from '~/helpers';

export const BlogsMetaMixins = defineNuxtComponent({
  data() {
    return {
      keyword: '',
    };
  },

  created() {
    const route = useRoute();
    if (route.query.q) {
      this.keyword = route.query.q as string;
      useSeoMeta({
        title: this.__computedTitle,
      });
    }
  },

  computed: {
    __computedTitle() {
      return `${capitalizeFirstLetters(this.keyword)} Blogs`;
    },

    title() {
      if (this.keyword) {
        return {
          text: this.__computedTitle,
          type: 'keyword',
        };
      }
      return {
        text: this.vars.primaryTitleText,
        type: 'title',
      };
    },
  },

  methods: {
    async onSearched() {
      const router = useRouter();
      if (this.keyword && this.keyword.toString().trim()) {
        await router.push({
          query: {
            q: this.keyword,
          },
        });
      } else {
        await router.push({});
      }
    },
  },

  watch: {
    'title.text'(e) {
      if (e) {
        useSeoMeta({
          title: e,
        });
      }
    },
  },
});
