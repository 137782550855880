<template>
  <div class="section-product-header" style="background-color: var(--white)">
    <lazy-theme2-base-not-found :is-full-screen-error="true" :title="vars.titleText">
      <template #description>
        <div style="color: var(--brown--secondary)" class="tw-mt-10 tw-text-2xl">
          {{ vars.descriptionText }}
        </div>
        <div class="tw-mt-5 tw-flex tw-flex-row tw-justify-center">
          <lazy-common-inputs-nc-button
            :label="vars.buttonLabelText"
            :is-link="true"
            link="/"
          ></lazy-common-inputs-nc-button>
        </div>
      </template>
    </lazy-theme2-base-not-found>
  </div>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'NotFoundPage',

  mixins: [ComponentMixin],

  setup() {
    const event = useRequestEvent();

    // event will be undefined in the browser
    if (event) {
      // Set the status message as well
      setResponseStatus(event, 404, 'Page Not Found');
    }
  },
});
</script>
