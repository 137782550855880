<template>
  <div class="project-content max-width-medium d-flex flex-column nc-text tw-gap-y-3">
    <div class="subtitle">{{ property.order }}</div>
    <h3 class="heading-small" :class="showFullTitle ? '' : 'tw-line-clamp-3 tw-h-[100px]'">
      {{ property.advert_heading }}
    </h3>
    <p class="text-size-regular neuron-capitalise tw-line-clamp-2 tw-h-[75px] tw-py-4">
      {{ property.display_address }}
    </p>
    <div v-if="property && property.price" class="tw-h-[60px]">
      <div v-if="property.price.type" class="text-size-small neuron-capitalise">
        {{ property.price.type.name }}
      </div>
      <div class="price">
        {{ property.price.price }}
      </div>
    </div>
    <div class="d-flex flex-row flex-wrap justify-content-between">
      <div class="d-flex flex-column">
        <div class="property-criteria__label">BEDROOM</div>
        <div class="property-criteria__value">
          {{ property.bedroom }}
        </div>
      </div>
      <div class="d-flex flex-column">
        <div class="property-criteria__label">BATHROOM</div>
        <div class="property-criteria__value">
          {{ property.bathroom }}
        </div>
      </div>
      <div class="d-flex flex-column">
        <div class="property-criteria__label">RECEPTION</div>
        <div class="property-criteria__value">
          {{ property.reception }}
        </div>
      </div>
    </div>
    <div class="margin-top margin-medium mt-auto">
      <nuxt-link
        data-w-id="be7ec0df-c1d4-01e9-596d-897784db125e"
        :to="property.detail_page_url"
        class="button outline w-inline-block"
        style="width: 150px"
        :external="true"
      >
        <div>VIEW PROPERTY</div>
        <div
          style="
            -webkit-transform: translate3d(-102%, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
            -moz-transform: translate3d(-102%, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
            -ms-transform: translate3d(-102%, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
            transform: translate3d(-102%, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
          "
          class="hover-underline"
        ></div>
      </nuxt-link>
    </div>
  </div>
</template>

<script lang="ts">
import type { PropType } from 'vue';
import type { PropertyItemComponent } from '~/units/properties/types';

export default defineNuxtComponent({
  name: 'PropertyItem',

  props: {
    property: {
      required: true,
      type: Object as PropType<PropertyItemComponent>,
    },

    showFullTitle: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
});
</script>

<style scoped>
.price {
  color: var(--brown);
  font-size: 1.5rem;
}

.property-criteria__label {
  font-weight: 300;
  color: var(--brown--secondary);
  font-size: 0.8rem;
  line-height: 14.35px;
  letter-spacing: 5px;
}

.property-criteria__value {
  color: var(--dark-brown);
}

.text-size-regular {
  width: unset;
}
</style>
