<template>
  <section class="section-services-content md:tw-py-16" :style="`background-color: var(--white)`">
    <div class="page-padding">
      <div class="container-large">
        <div class="padding-bottom">
          <div class="d-flex flex-row justify-content-center w-100 tw-mb-8">
            <h2 class="heading-medium">{{ vars.titleText }}</h2>
          </div>
          <Swiper
            :modules="[SwiperNavigation]"
            :slides-per-view="3"
            :space-between="30"
            effect="fade"
            :navigation="{
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            }"
            :breakpoints="{
              320: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              1080: {
                slidesPerView: 2,
                spaceBetween: 42,
              },
              1336: {
                slidesPerView: 3,
                spaceBetween: 42,
              },
            }"
            class="slider"
            @swiper="onSwiper"
          >
            <SwiperSlide
              v-for="(faq, index) in faqs"
              :key="`faq-${index}`"
              class="d-flex flex-column justify-content-center align-items-center"
            >
              <div class="margin-top">
                <div data-w-id="24fae542-f661-fbe8-51e2-b81661c752d3" style="height: 100%">
                  <library-iframe :src="faq.video" :w-ratio="1.49" :h-ratio="1"> </library-iframe>
                </div>
                <h3 class="heading-xsmall tw-mt-8 tw-h-[50px] tw-overflow-hidden tw-text-ellipsis">
                  {{ faq.question }}
                </h3>
                <p class="tw-mt-2">{{ faq.answer }}</p>
              </div>
            </SwiperSlide>
            <div class="controls min-[1336px]:tw-hidden">
              <div
                data-w-id="080a9ca2-7a2d-2ca3-3b22-f42b27569daf"
                class="slide-left-arrow w-slider-arrow-left"
                @click="() => swiper.slidePrev()"
              >
                <img
                  src="/cdn/theme2/images/left-arrow.svg"
                  loading="lazy"
                  alt="Left Arrow - Inner Webflow Template"
                  class="arrow-icon slider-arrow-transform"
                />
                <div class="slide-circle slider-circle-transform"></div>
              </div>
              <div
                data-w-id="080a9ca2-7a2d-2ca3-3b22-f42b27569db1"
                class="slide-right-arrow w-slider-arrow-right"
                @click="() => swiper.slideNext()"
              >
                <img
                  src="/cdn/theme2/images/right-arrow.svg"
                  loading="lazy"
                  alt="Right Arrow - Inner Webflow Template"
                  class="arrow-icon slider-arrow-transform"
                />
                <div class="slide-circle right"></div>
              </div>
              <div class="slide-nav w-slider-nav w-round"></div>
            </div>
          </Swiper>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'FAQ',

  mixins: [ComponentMixin],

  data() {
    return {
      swiper: {},
      faqs: [] as { [key: string]: string | number | null }[],
    };
  },

  created() {
    this.faqs = this.groupedVariables.faqs;
  },

  methods: {
    onSwiper(swiper: object) {
      this.swiper = swiper;
    },
  },
});
</script>

<style scoped>
h3 {
  font-size: 1.3rem;
}

.controls {
  height: 80px;
  margin-top: 20px;
  text-align: center;
}

.slide-left-arrow,
.slide-right-arrow {
  bottom: unset;
}
</style>
