<template>
  <section class="section-about-achievements" style="background-color: var(--dark-brown)">
    <div class="page-padding">
      <div class="container-large">
        <div class="padding-vertical padding-xhuge">
          <div class="about-achievements-wrapper">
            <div class="inner-content">
              <div class="max-width-medium">
                <h2
                  data-w-id="ad49ab33-554a-b3fb-2bd6-50fd71d9554a"
                  class="heading-medium"
                  style="color: var(--white); font-size: 4.5rem"
                >
                  {{ vars.titleText }}
                </h2>
              </div>
              <div class="margin-top margin-small">
                <div class="max-width-small">
                  <p class="text-size-small" style="color: var(--white)">
                    {{ vars.descriptionText }}
                  </p>
                </div>
              </div>
            </div>
            <div
              class="w-layout-grid achievements-component-grid"
              :class="{
                'w-layout-grid-3': achievements.length === 3,
              }"
            >
              <div
                v-for="(achievement, index) in achievements"
                id="w-node-df581805-ecef-5212-b0ff-80d0a5a3d09d-89606d85"
                :key="`achievement-${index}`"
                class="inner-content"
              >
                <div class="achievements-number">{{ achievement.value }}</div>
                <div class="text-size-regular">{{ achievement.name }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';
export default defineNuxtComponent({
  name: 'Achievements',

  mixins: [ComponentMixin],

  data() {
    return {
      achievements: [] as { [key: string]: string | number | null }[],
    };
  },

  created() {
    this.achievements = this.groupedVariables.achievements;
  },
});
</script>
<style scoped>
.w-layout-grid {
  grid-template-columns: 1fr 1fr;
}

.w-layout-grid-3 {
  grid-template-columns: 1fr 1fr 1fr !important;
}

.w-layout-grid-3.achievements-component-grid {
  grid-column-gap: 1rem;
  grid-row-gap: 2rem;
}
.about-achievements-wrapper {
  justify-content: space-between;
}
.achievements-number {
  font-family: 'Ivy Mode';
  font-weight: 300;
  color: var(--white);
}
.text-size-regular {
  color: var(--white);
  width: unset !important;
  max-width: 216px;
}

@media (max-width: 991px) {
  .w-layout-grid-3 {
    grid-template-columns: 1fr !important;
  }
  .text-size-regular {
    max-width: unset;
  }
}
</style>
