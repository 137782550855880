<template>
  <section class="section-services-content pt-5" :style="`background-color: var(--white)`">
    <div class="">
      <div class="w-100">
        <div class="padding-bottom padding-huge">
          <div class="d-flex flex-row justify-content-center w-100 pb-5">
            <h2 class="heading-medium">{{ vars.titleText }}</h2>
          </div>
          <Swiper
            :modules="[SwiperNavigation]"
            :slides-per-view="1"
            :initial-slide="1"
            effect="fade"
            :navigation="{
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            }"
            :centered-slides="true"
            :breakpoints="{
              320: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              1080: {
                slidesPerView: 2,
                spaceBetween: 100,
              },
              1336: {
                slidesPerView: 3,
                spaceBetween: 100,
              },
            }"
            class="slider"
            @swiper="onSwiper"
          >
            <SwiperSlide
              v-for="(item, index) in testimonials"
              :key="`testimonial-${index}`"
              class="d-flex flex-column justify-content-center align-items-center"
            >
              <span class="d-flex gap-3 tw-mb-5"
                ><svg
                  v-for="(i, key) in item.rating"
                  :key="`rating-${key}`"
                  width="24"
                  height="24"
                  viewBox="0 0 36 36"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.7375 33L11.175 22.4625L3 15.375L13.8 14.4375L18 4.5L22.2 14.4375L33 15.375L24.825 22.4625L27.2625 33L18 27.4125L8.7375 33Z"
                    fill="#AF624E"
                  />
                </svg>
              </span>
              <p class="text-center tw-text-xl lg:tw-text-3xl" style="max-width: 680px; min-height: 130px">
                {{ item.testimonial }}
              </p>
              <p
                style="
                  text-transform: uppercase;
                  font-size: 18px;
                  margin-top: 20px;
                  letter-spacing: 5px;
                  font-weight: 300;
                "
              >
                {{ item.author }}
              </p>
            </SwiperSlide>
            <div class="controls">
              <div
                data-w-id="080a9ca2-7a2d-2ca3-3b22-f42b27569daf"
                class="slide-left-arrow w-slider-arrow-left"
                @click="() => swiper.slidePrev()"
              >
                <img
                  src="/cdn/theme2/images/left-arrow.svg"
                  loading="lazy"
                  alt="Left Arrow - Inner Webflow Template"
                  class="arrow-icon slider-arrow-transform"
                />
                <div class="slide-circle slider-circle-transform"></div>
              </div>
              <div
                data-w-id="080a9ca2-7a2d-2ca3-3b22-f42b27569db1"
                class="slide-right-arrow w-slider-arrow-right"
                @click="() => swiper.slideNext()"
              >
                <img
                  src="/cdn/theme2/images/right-arrow.svg"
                  loading="lazy"
                  alt="Right Arrow - Inner Webflow Template"
                  class="arrow-icon slider-arrow-transform"
                />
                <div class="slide-circle right"></div>
              </div>
              <div class="slide-nav w-slider-nav w-round"></div>
            </div>
          </Swiper>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'Testimonials',

  mixins: [ComponentMixin],

  data() {
    return {
      swiper: {},
      testimonials: [] as { [key: string]: string | number | null }[],
    };
  },

  created() {
    // TODO: getGroupedVariables()
    this.testimonials = this.groupedVariables.testimonials;
  },

  methods: {
    onSwiper(swiper: object) {
      this.swiper = swiper;
    },
  },
});
</script>

<style scoped>
.office-detail__label {
  font-weight: 300;
  color: var(--brown--secondary);
  font-size: 0.8rem;
  line-height: 14.35px;
  letter-spacing: 5px;
  text-transform: uppercase;
}
.office-detail__value {
  font-weight: 300;
  color: var(--dark-brown);
  font-size: 1.3rem;
  padding-right: 4rem;
}
.w-min-content {
  width: min-content;
}

.office-detail-title {
  width: min-content;
  font-size: 6.25rem;
}

.social-media {
  background: var(--dark-brown);
  width: 36px;
  height: 36px;
  flex-shrink: 1;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white);
}

.controls {
  height: 80px;
  margin-top: 20px;
  text-align: center;
}

.swiper-slide {
  opacity: 0.5;
}
.swiper-slide-active {
  opacity: 1;
}

.slide-left-arrow,
.slide-right-arrow {
  bottom: unset;
}
</style>
