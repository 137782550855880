<template>
  <div class="section-home-projects vh-100" style="background-color: var(--seashell)">
    <div id="wrapper">
      <div class="video-content">
        <h2
          style="color: var(--white); font-weight: 200; text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5)"
          class="heading-large"
          v-html="overlayTitle"
        ></h2>
        <div
          class="button-flex-wrapper mt-4"
          :class="buttonsLayout === 2 ? 'tw-flex tw-flex-col tw-items-start tw-gap-y-2 md:tw-flex-row' : ''"
        >
          <nuxt-link
            id="w-node-aba3c3ea-3ff0-3543-f79c-42814ece1460-89606db2"
            :external="true"
            :to="variables.mainButtonUrl"
            class="button secondary w-inline-block"
            style="background-color: var(--white); color: var(--dark-brown)"
          >
            <div class="button-label">
              {{ variables.mainButtonText }}
            </div>
            <div style="height: 0%" class="overlay"></div>
          </nuxt-link>
          <nuxt-link
            data-w-id="be7ec0df-c1d4-01e9-596d-897784db125e"
            :external="true"
            :to="variables.secondButtonUrl"
            class="button outline w-inline-block"
            style="color: var(--white)"
          >
            <div>{{ variables.secondButtonText }}</div>
            <div style="background-color: var(--white)" class="hover-underline"></div>
          </nuxt-link>
        </div>
      </div>
      <div class="video-wrap">
        <video id="video" muted :loop="true" playsinline preload="none" :src="variables.video">
          <source type="video/mp4" :video-hd="variables.video" :video-sd="variables.video" />
        </video>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'HomeIntro',

  mixins: [ComponentMixin],

  computed: {
    overlayTitle() {
      return getVariable(this.component, 'overlay_title-html');
    },

    buttonsLayout() {
      return this.vars.mobileButtonLayoutNumber;
    },

    variables() {
      return {
        video: getVariable(this.component, 'background-video'),
        mainButtonText: getVariable(this.component, 'main_button_title-text'),
        mainButtonUrl: getVariable(this.component, 'main_button_link-url'),
        secondButtonText: getVariable(this.component, 'second_button_title-text'),
        secondButtonUrl: getVariable(this.component, 'secondary_button-url'),
      };
    },
  },

  mounted() {
    const myVideo = document.getElementById('video');
    if (myVideo !== undefined) {
      myVideo.play();
    }
  },
});
</script>

<style scoped>
#wrapper {
  position: relative;
}
#wrapper video {
  position: relative;
  z-index: 100;
}
#wrapper .video-content {
  position: absolute;
  display: block;
  z-index: 150;
  left: 3%;
  bottom: 6%;
}

.video-wrap {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  background: no-repeat center center/cover;
}
video {
  min-width: 100%;
  min-height: 100%;
  z-index: 1;
  object-fit: cover;
  background: no-repeat center center/cover;
}
</style>
